import $ from 'jquery';

export class BaseAutocomplete {
    AUTOCOMPLETE_ID;
    AUTOCOMPLETE_UID;
    AUTOCOMPLETE_TBL;
    CONTAINER;
    #DEPENDENT_AUTOCOMPLETES = {};
    LAST_VALUE = '';

    constructor(aid, tbl, uid) {
        if(!aid) {
            console.warn('No autocomplete ID provided.');
            return;
        }

        this.AUTOCOMPLETE_ID = aid;
        this.AUTOCOMPLETE_TBL = tbl;
        this.AUTOCOMPLETE_UID = uid;
        window.dispatchEvent(new CustomEvent('RegisterAutocomplete', {
            detail: { id: this.AUTOCOMPLETE_ID, instance: this }
        }));
    }

    setup(uid) {
        if(!app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid]) {
            console.warn('Unable to setup autocomplete');
            return;
        }
        this.CONTAINER = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid];
        window.addEventListener('AutocompleteDependHook', (e) => {
            this.#DEPENDENT_AUTOCOMPLETES[e.detail.uid] = e.detail.instance;
        });
    }

    getAjaxData(autocompleteData = {}) {
        const dependsOn = this.CONTAINER.input.data('depends-on');

        let data = {
            tbl_ref: autocompleteData.tbl_ref ? autocompleteData.tbl_ref : app.URI[1],
            type:  (app.OPTIONS.form ) ? 'form' : app.URI[1]
        };

        if( autocompleteData.operatorId ) {
            data.operator_id = autocompleteData.operatorId;
        }

        if(dependsOn && dependsOn.length > 0) {
            if(!app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_PROVIDERS[dependsOn]) {
                console.warn('Unable to load dependency autocomplete.');
                return;
            }

            data[dependsOn] = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_PROVIDERS[dependsOn].getVal();
            window.dispatchEvent(new CustomEvent('AutocompleteDependHook', {
                detail: {
                    dependsOn: dependsOn,
                    instance: this,
                    id: this.AUTOCOMPLETE_ID,
                    uid: this.AUTOCOMPLETE_UID
                }
            }));

            if(!data[dependsOn] || data[dependsOn].length === 0) {
                if(this.CONTAINER.input_hidden.val() !== '') {
                    this.#clear();
                    this.CONTAINER.input.prop('disabled', true);
                    app.AUTOCOMPLETE_CORE.hideAll(this.AUTOCOMPLETE_UID);
                }
                return false;
            }
        } else if(dependsOn && dependsOn.length > 0 && !app.AUTOCOMPLETE_CORE[dependsOn]) {
            if(this.CONTAINER.input_hidden.val() !== '') {
                this.#clear();
                this.CONTAINER.input.prop('disabled', true);
                app.AUTOCOMPLETE_CORE.hideAll(this.AUTOCOMPLETE_UID);
            }
            return false;
        } else {
            this.CONTAINER.input.prop('disabled', false);
            app.AUTOCOMPLETE_CORE.showLoading(this.AUTOCOMPLETE_UID);
        }

        if( app.OPTIONS.dashboard ) {
            data.tbl_ref = app.OPTIONS.dashboard;
            data.type = 'dashboard';
        } else if( app.URI[1] === 'list' || app.URI[1] === 'planner' ) {
            data.tbl_ref = app.URI[2];
        }

        // references purposes
        if( $.isNumeric(app.URI[2]) ){
            data.id = app.URI[2];
        }

        if( app.URI[1] === autocompleteData.tbl ) {
            if( $.isNumeric(app.URI[2]) ){
                data.exclude_id = app.URI[2];
            }
        } else if($.isNumeric(app.URI[2])) {
            data.tbl_id = app.URI[2];
        }

        return data;
    }

    selectAuto(e, ui, uid) {
        if(e) {
            e.preventDefault();
        }

        // update dom
        this.CONTAINER.input_hidden.val(ui.item.value).trigger('change');
        this.CONTAINER.input.val(ui.item.label);

        // change buttons
        app.AUTOCOMPLETE_CORE.showClear();

        if( app.LIST && app.DOM.filter_submit && !this.CONTAINER.input.data('disableAutoSubmit') ) {
            app.DOM.filter_submit.click();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboard') === true) {
            app.DASHBOARD_CORE.updateFilters();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboardWidget') === true) {
            this.CONTAINER.input.closest('.grid-stack-item').get(0).dispatchEvent(new CustomEvent('AutoCompleteSelect', {
                detail: {
                    id: this.AUTOCOMPLETE_ID,
                    tbl: this.AUTOCOMPLETE_TBL,
                    data: ui.item,
                    filterId: this.CONTAINER.input.data('filterId'),
                }
            }));
        }

        if(this.LAST_VALUE !== ui.item.value) {
            this.LAST_VALUE = ui.item.value;
            window.dispatchEvent(new CustomEvent('AutoCompleteSelect', {
                detail: {
                    id: this.AUTOCOMPLETE_ID,
                    tbl: this.AUTOCOMPLETE_TBL,
                    data: ui.item,
                    input: this.CONTAINER.input
                }
            }));
            this.notifyDependents();
        }
    }

    autocompleteOptions(autocompleteData, uid) {
        return {
            select: (e, ui) => this.selectAuto(e, ui, uid),
            focus: (e) => e.preventDefault(),
        };
    }

    onFocus(e, uid) {}
    onBlur(e, uid) {

        if( !app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID] || !app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID].data || app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID].errored) {
            return;
        }
        const hasVal = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID].data.filter((opt) => this.CONTAINER.input_hidden.val() === opt.value);
        if(this.CONTAINER && (this.CONTAINER.input_hidden.val() === '' || hasVal.length === 0)) {
            this.CONTAINER.input.val('');
            this.CONTAINER.input_hidden.val('');
            app.AUTOCOMPLETE_CORE.showSearch(uid);
            if(this.LAST_VALUE !== '') {
                this.LAST_VALUE = '';
                window.dispatchEvent(new CustomEvent('AutoCompleteClear', {
                    detail: {
                        id: this.AUTOCOMPLETE_ID,
                        tbl: this.AUTOCOMPLETE_TBL,
                    }
                }));
                this.notifyDependents();
            }
        }
    }

    onKeyup(e, uid) {
        if(e.currentTarget.value === '') {
            app.AUTOCOMPLETE_CORE.showSearch(uid);
            this.CONTAINER.input_hidden.val('').trigger('change');
            if(this.LAST_VALUE !== '') {
                this.LAST_VALUE = '';
                window.dispatchEvent(new CustomEvent('AutoCompleteClear', {
                    detail: {
                        id: this.AUTOCOMPLETE_ID,
                        tbl: this.AUTOCOMPLETE_TBL,
                    }
                }));
                this.notifyDependents();
            }
        } else {
            app.AUTOCOMPLETE_CORE.showClear(uid);
        }
    }
    onKeyDown(e, uid) {}

    clearSelection(e, _uid) {
        const el = $(e.currentTarget);
        if(el.hasClass('disabled') || (app.OPTIONS.form && confirm("Remove?") === false)) {
            return;
        }

        this.#clear();
    }

    #clear() {
        this.CONTAINER.input.val('').trigger('change');
        this.CONTAINER.input_hidden.val('').focus().trigger('change');
        app.AUTOCOMPLETE_CORE.showSearch(this.AUTOCOMPLETE_UID);

        if(app.URI[1] === 'list' && !this.CONTAINER.input.data('disableAutoSubmit')) {
            app.DOM.filter_submit.click();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboard') === true) {
            app.DASHBOARD_CORE.updateFilters();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboardWidget') === true) {
            this.CONTAINER.input.closest('.grid-stack-item').get(0).dispatchEvent(new CustomEvent('AutoCompleteClear', {
                detail: {
                    filterId: this.CONTAINER.input.data('filterId'),
                }
            }));
        }

        if(this.LAST_VALUE !== '') {
            this.LAST_VALUE = '';
            window.dispatchEvent(new CustomEvent('AutoCompleteClear', {
                detail: {
                    id: this.AUTOCOMPLETE_ID,
                    tbl: this.AUTOCOMPLETE_TBL,
                }
            }));
            this.notifyDependents();
        }
    }

    getVal() {
        if(!this.CONTAINER || !this.CONTAINER.input_hidden) {
            return false;
        }

        return this.CONTAINER.input_hidden.val();
    }

    notifyDependents() {
        Object.keys(this.#DEPENDENT_AUTOCOMPLETES).forEach((id) => {
            this.#DEPENDENT_AUTOCOMPLETES[id].CONTAINER.input.get(0).dispatchEvent(new CustomEvent('AutocompleteDependencyUpdated'));
        });
    }
}